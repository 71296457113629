import type { ComponentPropsWithoutRef, ComponentPropsWithRef, JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { ButtonLink } from '@change-corgi/design-system/components/actions';
import { ProgressIndicator } from '@change-corgi/design-system/components/navigation';
import { Box, Flex } from '@change-corgi/design-system/layout';

import { Card } from '../Card';

import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { Media, type MediaProps } from './components/Media';

type Props = Omit<
	Pick<ComponentPropsWithoutRef<typeof Body>, 'onEllipsisClick' | 'onHeadingClick'> &
		MediaProps &
		ComponentPropsWithRef<typeof Card> & {
			completionPercentage?: number;
			descriptionHtml?: string;
			heading: string;
			onSignButtonClick?: ComponentPropsWithoutRef<typeof ButtonLink>['onClick'];
			petitionSignUrl?: ComponentPropsWithoutRef<typeof ButtonLink>['to'];
			petitionId: string;
			petitionUrl: string;
			showSignButton: boolean;
			supporterCount: number;
			supporterCountLocalized?: string;
			userAvatarSrc?: string;
			userName: string;
		},
	'children'
>;

export type BadgeType = MediaProps['badgeType'];

function getDescriptionLineClamp({
	showSignButton,
	completionPercentage,
}: Pick<Props, 'completionPercentage' | 'showSignButton'>) {
	if (completionPercentage) return 2;
	if (showSignButton) return 3;
	return 3;
}

function getHeight({ descriptionHtml, showSignButton }: Pick<Props, 'descriptionHtml' | 'showSignButton'>) {
	if (!descriptionHtml) return null;
	if (showSignButton) return '460px';
	return '400px';
}
/**
 * @doc $DOC:PetitionCard
 */
export function PetitionCard({
	badgeType,
	completionPercentage,
	descriptionHtml,
	dmName,
	heading,
	imageUrl,
	onEllipsisClick,
	onHeadingClick,
	onSignButtonClick,
	petitionSignUrl,
	petitionId,
	petitionUrl,
	promoterCount,
	promoterCountLocalized,
	promotersUrl,
	showSignButton,
	showVictory,
	supporterCount,
	supporterCountLocalized,
	userAvatarSrc,
	userName,
}: Props): JSX.Element {
	return (
		<Card
			sx={{
				backgroundColor: 'primary-white',
				overflow: 'hidden',
				boxShadow: 0,
				height: getHeight({ descriptionHtml, showSignButton }),
			}}
			variant="primary"
			data-qa="petition-card"
			data-petition-id={petitionId}
		>
			<Flex sx={{ flexDirection: 'column', height: '100%' }}>
				<Media
					badgeType={badgeType}
					dmName={dmName}
					imageUrl={imageUrl}
					promoterCount={promoterCount}
					promoterCountLocalized={promoterCountLocalized}
					promotersUrl={promotersUrl}
					showVictory={showVictory}
					supporterCount={supporterCount}
					supporterCountLocalized={supporterCountLocalized}
				/>
				<Flex pt={16} px={16} sx={{ flex: 1, flexDirection: 'column' }}>
					<Box sx={{ flex: 1 }}>
						<Body
							descriptionHtml={descriptionHtml}
							descriptionLineClamp={getDescriptionLineClamp({ showSignButton, completionPercentage })}
							heading={heading}
							onEllipsisClick={onEllipsisClick}
							onHeadingClick={onHeadingClick}
							petitionUrl={petitionUrl}
						/>
					</Box>

					{completionPercentage !== undefined && (
						<ProgressIndicator mb={16} completionPercentage={completionPercentage} variant="purple" />
					)}

					<Footer
						supporterCount={supporterCount}
						supporterCountLocalized={supporterCountLocalized}
						userAvatarSrc={userAvatarSrc}
						userName={userName}
					/>

					{showSignButton && petitionSignUrl && (
						<ButtonLink
							mb={16}
							variant="secondaryEmphasis"
							onClick={onSignButtonClick}
							to={petitionSignUrl}
							data-qa="signable-petition-card-button"
						>
							<Translate value="design-system.petition-card.sign-button-label" />
						</ButtonLink>
					)}
				</Flex>
			</Flex>
		</Card>
	);
}

import type { JSX } from 'react';

import { initialFocusAttributes } from '../shared/consts';
import type {
	CloseModalWithResultsFunction,
	ModalOptions,
	ModalOptionsDefault,
	ModalOptionsProps,
	ModalProps,
} from '../shared/types';

export type Props<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined> = Pick<
	ModalProps<OPTIONS, RESULT, STATE>,
	'options' | 'persistentState' | 'onPersistentStateUpdate'
> &
	Pick<ModalOptionsProps<OPTIONS, RESULT, STATE>, 'body'> & {
		modalZIndex: number;
		closeModal: CloseModalWithResultsFunction<RESULT>;
	};

const noop = () => {};

export function ModalBody<OPTIONS extends ModalOptions = ModalOptionsDefault, RESULT = undefined, STATE = undefined>({
	options,
	body: Body,
	closeModal,
	modalZIndex,
	persistentState,
	onPersistentStateUpdate,
}: Props<OPTIONS, RESULT, STATE>): JSX.Element {
	return (
		<Body
			options={options}
			initialFocusAttributes={initialFocusAttributes}
			closeModal={closeModal}
			persistentState={persistentState}
			updatePersistentState={onPersistentStateUpdate || noop}
			modalZIndex={modalZIndex}
		/>
	);
}

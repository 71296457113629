import { Children, cloneElement, isValidElement, useId } from 'react';
import type { ComponentProps, ComponentPropsWithRef, ForwardedRef, JSX } from 'react';

import { forwardRef } from '@change-corgi/core/react/core';
import { Box } from '@change-corgi/design-system/layout';

import type { TabsComponentElementType, TabsComponentName } from './types';

import type { Tab, TabGroup, TabPanel } from './index';

type Props = Omit<ComponentPropsWithRef<typeof Box>, 'children'> & {
	children: ReadonlyArray<
		| React.ReactElement<ComponentProps<typeof TabGroup>>
		| React.ReactElement<ComponentProps<typeof TabPanel>>
		| ReadonlyArray<React.ReactElement<ComponentProps<typeof TabPanel>>>
		| null
		| false
		| undefined
	>;
	selected: string;
};

function isTabComponent(type: TabsComponentElementType, name: TabsComponentName) {
	if (typeof type === 'string') return false;
	return type.tabsComponentName === name;
}

function TabsInner({ children, selected, sx, ...rest }: Props, ref: ForwardedRef<HTMLDivElement>): JSX.Element {
	const tabsId = useId();

	function renderChildren() {
		return Children.map(children, (child) => {
			if (!isValidElement<ComponentProps<typeof TabGroup> | ComponentProps<typeof TabPanel>>(child)) return null;
			if (isTabComponent(child.type as TabsComponentElementType, 'TabGroup')) {
				return cloneElement<ComponentProps<typeof TabGroup> | ComponentProps<typeof TabPanel>>(child, {
					children: Children.map(child?.props.children, (tabGroupChild) => {
						if (!isValidElement<ComponentProps<typeof Tab>>(tabGroupChild)) return null;
						if (isTabComponent(tabGroupChild.type as TabsComponentElementType, 'Tab')) {
							const id = `tab-${tabsId}${tabGroupChild.props.name}`;
							// eslint-disable-next-line @typescript-eslint/naming-convention
							return cloneElement(tabGroupChild, { id, __internal_selected: selected === tabGroupChild.props.name });
						}

						return tabGroupChild;
					}),
				});
			}

			if (isTabComponent(child.type as TabsComponentElementType, 'TabPanel')) {
				// eslint-disable-next-line @typescript-eslint/naming-convention
				if (!isValidElement<ComponentProps<typeof TabPanel> & { 'aria-labelledby': string }>(child)) return null;
				const result = cloneElement(child, {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'aria-labelledby': `tab-${tabsId}${child.props.name}`,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					__internal_selected: selected === child.props.name,
				});
				return result;
			}

			return child;
		});
	}

	return (
		<Box
			sx={{
				width: '100%',
				...sx, // only required for storybook to take overrides into account
			}}
			{...rest}
			ref={ref}
		>
			{renderChildren()}
		</Box>
	);
}

/**
 * @doc $DOC:Tabs
 */
export const Tabs = forwardRef(TabsInner);
